
import { defineComponent } from "vue";
import Dashboard from "./components/Dashboard";

export default defineComponent({
  name: "App",
  components: {
    Dashboard,
  },
});
